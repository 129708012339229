<template>
  <main class="main">
    <section class="main__about">
      <h1>Страница находится в разработке</h1>
    </section>
  </main>
</template>

<script>

export default {
  name: 'PageNotFound',
  metaInfo () {
    return this.$seo(
      'common',
      'Страница находится в разработке',
      null,
      null,
      'Страница находится в разработке',
      null,
      null
    )
  }
}
</script>
